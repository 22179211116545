body {
  margin: 0;
  height:100%;
  position: relative;
  overflow: auto;
  -moz-osx-font-smoothing: grayscale;
}

html{
  height:100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
